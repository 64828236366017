import React from 'react'

const About = () => {
  return (
    <div className='md:flex md:justify-center'>
        <div className='md:w-2/3'>
       </div>
    </div>
  )
}

export default About
